const Logo = () => {
  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center'
    }}>
      <img
        src="../opta-logo.png"
        width={100}
        style={{
          alignItems: "center",
          display: "flex",
          margin: "10px 0 ",
          justifyContent: "center",
        }}
      />
    </div>
  );
};
export default Logo;
