import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import SetPin from './components/SetPin';
import Dashboard from './components/Dashboard';
import LockScreen from './components/LockScreen.js';
import ConfirmEmail from './components/ConfirmEmail.js';
import ResetPassword from './components/ResetPassword.js';
import PasswordProtect from './components/PasswordProtect'; // Добавяме новия компонент

function App() {
  const [isLocked, setIsLocked] = useState(false);
  const [hasToken, setHasToken] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const isLocked = localStorage.getItem('isLocked');
    if (token) {
      setHasToken(true); // Потребителят има активен токен
    }
    if (isLocked) {
      setIsLocked(true);
    }
  }, []);

  const handleLockScreen = () => {
    setIsLocked(true); // Заключваме приложението
  };

  const handleUnlock = () => {
    setIsLocked(false); // Отключваме приложението
  };

  if (isLocked) {
    return <LockScreen onUnlock={handleUnlock} />;
  }

  return (
    <PasswordProtect> 
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/set-pin" element={<SetPin onLock={handleLockScreen} />} />
          <Route path="/dashboard" element={<Dashboard onLock={handleLockScreen} />} />
          <Route path="/confirm/*" element={<ConfirmEmail />} />
          <Route path="/reset-password/*" element={<ResetPassword />} />
        </Routes>
      </Router>
    </PasswordProtect>
  );
}

export default App;
