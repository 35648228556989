import React, { useState, useEffect } from 'react';
import { Button, TextField, Container, Typography, Grid, Box, ToggleButton, ToggleButtonGroup, Paper, Alert, AlertTitle } from '@mui/material';
import { loginUser, loginWithPin } from '../services/api';
import { Link, useNavigate } from 'react-router-dom';
import Logo from './Logo';

const Login = () => {
  const [loginType, setLoginType] = useState('email');
  const [error, setError] = useState(''); // Съхраняваме грешката от бекенда

  const [formData, setFormData] = useState({ email: '', password: '', pinCode: '' });
  const navigate = useNavigate();

  useEffect(() => {
    if (loginType === 'pin') {
      const handleKeyDown = (event) => {
        const key = event.key;
        if (!isNaN(key) && key >= '0' && key <= '9') {
          handlePinKeyPress(key);
        } else if (key === 'Backspace') {
          handlePinDelete();
        } else if (key === 'Enter') {
          handleSubmit();
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [formData.pinCode, loginType]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLoginTypeChange = (event, newLoginType) => {
    if (newLoginType !== null) {
      setLoginType(newLoginType);
    }
  };

  const handlePinKeyPress = (num) => {
    if (formData.pinCode.length < 6) {
      setFormData((prevData) => ({ ...prevData, pinCode: prevData.pinCode + num }));
    }
  };

  const handlePinDelete = () => {
    setFormData((prevData) => ({ ...prevData, pinCode: prevData.pinCode.slice(0, -1) }));
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      let response;
      if (loginType === 'email') {
        response = await loginUser({ email: formData.email, password: formData.password });
      } else {
        response = await loginWithPin({ pinCode: formData.pinCode });
      }
      localStorage.setItem('token', response.data.token);
      navigate('/dashboard');
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '50px' }}>
      <Paper elevation={3} style={{ padding: '30px' }}>
        <div style={{width: "100%", display: 'flex', justifyContent: 'center'}}>
          <Logo />
        </div>
   {error && (
          <Alert severity="error" style={{ marginBottom: '20px' }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}
        <ToggleButtonGroup
          value={loginType}
          exclusive
          onChange={handleLoginTypeChange}
          style={{ justifyContent: 'center', marginBottom: '20px' }}
          fullWidth
        >
          <ToggleButton value="email" style={{ flex: 1 }}>
            Login with Email
          </ToggleButton>
          <ToggleButton value="pin" style={{ flex: 1 }}>
            Login with PIN
          </ToggleButton>
        </ToggleButtonGroup>

        <form onSubmit={handleSubmit}>
          {loginType === 'email' ? (
            <>
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
                <Box textAlign="left" mt={1}>
                <Link
                  component="button"
                  variant="body2"
                  to={'/forgot-password'}
                >
                  Forgot Password?
                </Link>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h5" align="center" gutterBottom>
                {formData.pinCode.padEnd(6, '•')}
              </Typography>

              {/* Клавиатурата */}
              <Grid container spacing={2} justifyContent="center" style={{ maxWidth: 300, margin: '0 auto' }}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
                  <Grid item xs={4} key={num}>
                    <Button
                      variant="contained"
                      onClick={() => handlePinKeyPress(num.toString())}
                      style={{ width: '100%', height: '60px' }}
                      size="large"
                    >
                      {num}
                    </Button>
                  </Grid>
                ))}
              
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    onClick={() => handlePinKeyPress('0')}
                    style={{ width: '100%', height: '60px' }}
                    size="large"
                  >
                    0
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handlePinDelete}
                    style={{ width: '100%', height: '60px' }}
                    size="large"
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={loginType === 'pin' && formData.pinCode.length !== 6}
            style={{ marginTop: '30px', padding: '10px' }}
          >
            Login
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={()=>{
              navigate('/register');
            }}
            fullWidth
            style={{ marginTop: '10px', padding: '10px' }}
          >
            Register
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
