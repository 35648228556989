import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Typography, Box } from '@mui/material';
import Logo from './Logo';

const PasswordProtect = ({ children }) => {
  const [hasAccess, setHasAccess] = useState(false);
  const [password, setPassword] = useState('');
  const correctPassword = 'mitkoogisky2024'; // Задай паролата тук
  
  useEffect(() => {
    const savedTimestamp = localStorage.getItem('accessTimestamp');
    const savedAccess = localStorage.getItem('hasAccess');
    
    const oneDay = 86400000; // 1 ден в милисекунди
    const now = Date.now();

    if (savedAccess && savedTimestamp && now - savedTimestamp < oneDay) {
      setHasAccess(true);
    } else {
      localStorage.removeItem('accessTimestamp');
      localStorage.removeItem('hasAccess');
    }
  }, []);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setHasAccess(true);
      localStorage.setItem('hasAccess', 'true');
      localStorage.setItem('accessTimestamp', Date.now());
    } else {
      alert('Грешна парола!');
    }
  };

  if (!hasAccess) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="100vh" 
        bgcolor="#f5f5f5"
      >
        <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, textAlign: 'center', borderRadius: 2 }}>
          <Logo />
          <Typography variant="h5" gutterBottom>
            Въведи парола за достъп
          </Typography>
          <form onSubmit={handlePasswordSubmit}>
            <TextField
              label="Парола"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Влез
            </Button>
          </form>
        </Paper>
      </Box>
    );
  }

  return <>{children}</>; // Показваме приложението, ако има достъп
};

export default PasswordProtect;
