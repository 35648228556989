import React, { useState, useEffect } from 'react';
import { Button, Typography, Grid, Box, Snackbar, Alert } from '@mui/material';
import { loginWithPin } from '../services/api'; // Функция за проверка на ПИН кода

const LockScreen = ({ onUnlock }) => {
  const [pinCode, setPinCode] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    // Добавяме слушател за физическата клавиатура
    const handleKeyDown = (event) => {
      const key = event.key;

      if (!isNaN(key) && key >= '0' && key <= '9') {
        // Ако е натиснат цифров клавиш
        handleKeyPress(key);
      } else if (key === 'Backspace') {
        // Ако е натиснат "Backspace", трием последната цифра
        handleDelete();
      } else if (key === 'Enter') {
        // Ако е натиснат "Enter", изпращаме ПИН-а за отключване
        handleUnlock();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Премахваме слушателя за клавиатурата при напускане на компонента
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [pinCode]);

  const handleKeyPress = (num) => {
    if (pinCode.length < 4) {
      setPinCode((prevPin) => prevPin + num);
    }
  };

  const handleDelete = () => {
    setPinCode((prevPin) => prevPin.slice(0, -1));
  };

  const handleUnlock = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      showSnackbar('Token is missing. Please log in again.', 'error');
      return;
    }

    if (pinCode.length !== 4) {
      showSnackbar('Please enter exactly 4 digits for the PIN.', 'warning');
      return;
    }

    try {
      const response = await loginWithPin({ pinCode }, token); // Изпращаме ПИН и токен към бекенда
      const newToken = response.data.token;
      localStorage.setItem('token', newToken); 
      localStorage.removeItem('isLocked'); // Отключваме приложението
      showSnackbar('Successfully unlocked!', 'success');
      onUnlock(); // Извикваме функцията за отключване
    } catch (err) {
      console.error(err);
      showSnackbar('Invalid PIN', 'error');
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh" flexDirection="column">
          {/* Snackbar за показване на съобщения */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Typography variant="h4" gutterBottom>
        Enter your PIN to unlock
      </Typography>
      <Typography variant="h5" gutterBottom>
        {pinCode.padEnd(4, '•')} {/* Показва въведения ПИН, като скрива незапълнените места с "•" */}
      </Typography>

      <Grid container spacing={2} justifyContent="center" style={{ maxWidth: 300, marginTop: 20 }}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
          <Grid item xs={4} key={num}>
            <Button
              variant="contained"
              onClick={() => handleKeyPress(num.toString())}
              style={{ width: '100%', height: '60px' }}
            >
              {num}
            </Button>
          </Grid>
        ))}
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            style={{ width: '100%', height: '60px' }}
          >
            Delete
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => handleKeyPress('0')}
            style={{ width: '100%', height: '60px' }}
          >
            0
          </Button>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={handleUnlock}
        disabled={pinCode.length !== 4}
        style={{ marginTop: '20px' }}
      >
        Unlock
      </Button>

    
    </Box>
  );
};

export default LockScreen;
