import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Paper } from '@mui/material';
import { resetPassword } from '../services/api';
import Logo from './Logo';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const token  = window.location.pathname.split('/')[2]
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    try {
      const response = await resetPassword({ token, newPassword: password });
      setMessage(response.data.message);
      setTimeout(()=>{
        navigate('/login');
      },5000)
    } catch (err) {
      setMessage('Error resetting password. Please try again.');
      console.error(err);
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '50px' }}>
      <Paper elevation={3} style={{ padding: '30px' }}>
        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
          <Logo />
        </div>
        <Typography variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            label="New Password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Confirm New Password"
            name="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            style={{ marginTop: '20px', padding: '10px' }}
          >
            Reset Password
          </Button>
        </form>
        {message && (
          <Typography variant="body1" align="center" style={{ marginTop: '20px' }}>
            {message}
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default ResetPassword;
