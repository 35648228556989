import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, ButtonGroup } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Импортираме правилно jwtDecode
import Logo from './Logo';

const Dashboard = ({onLock}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({ firstName: '', lastName: '' });
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('You need to log in to access this page');
      navigate('/login');
    } else {
      try {
        const decodedToken = jwtDecode(token); // Декодираме токена
        const { firstName, lastName } = decodedToken; // Извличаме firstName и lastName
        setUserData({ firstName, lastName });
        setLoading(true);
      } catch (err) {
        console.error('Invalid token:', err);
        navigate('/login');
      }
    }
  }, [navigate]);
  const handleSetPinClick = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/set-pin');
    } else {
      alert('You need to log in to access this page');
      navigate('/login');
    }
  };
  const handleLogOut = () =>{
    localStorage.clear();
    navigate('/login');
  }

  const handleLock = () => {
    localStorage.setItem('isLocked', 'true');
    onLock();
  };
  return loading && 
    <Container sx={{display:'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
      <Logo />
      <Typography variant="h4" sx={{display:'flex', textAlign: 'center', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}} >
      Welcome, {userData.firstName} {userData.lastName}!
      </Typography>
      <Typography gutterBottom>
        Here you can manage your account and settings.
      </Typography>
      <ButtonGroup sx={{display: 'flex',flexDirection: 'column', width: 300, justifyContent: 'center', gap:3}}>
      <Button variant="contained" color="primary" onClick={handleSetPinClick}>
        Set PIN Code
      </Button>
    
      <Button variant="contained" color="secondary" onClick={handleLogOut}>
        Log out
      </Button>
      </ButtonGroup>
    </Container>
  
};

export default Dashboard;
