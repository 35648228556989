import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Button, CircularProgress } from '@mui/material';
import axios from 'axios';

const ConfirmEmail = () => {
  const token  = window.location.pathname.split('/')[2] // Взимаме токена от URL
  const [status, setStatus] = useState('loading'); // Статус за показване на състоянието
  const navigate = useNavigate();
  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await axios.get(`https://api.${window.location.host}/confirm/${token}`);
        if (response.data.success) {
          setStatus('success');
        } else {
          setStatus('error');
        }
      } catch (error) {
        console.error('Error confirming email:', error);
        setStatus('error');
      }
    };

    confirmEmail();
  }, [token]);

  const handleGoToLogin = () => {
    navigate('/login'); // Пренасочваме към страницата за вход
  };

  return (
    <Container>
      {status === 'loading' && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
          <Typography variant="h6">Confirming your email...</Typography>
        </div>
      )}

      {status === 'success' && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Typography variant="h4" color="primary" gutterBottom>
            Email confirmed successfully!
          </Typography>
          <Button variant="contained" color="primary" onClick={handleGoToLogin}>
            Go to Login
          </Button>
        </div>
      )}

      {status === 'error' && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Typography variant="h4" color="error" gutterBottom>
            Failed to confirm email. Please try again.
          </Typography>
          <Button variant="contained" color="secondary" onClick={handleGoToLogin}>
            Go to Login
          </Button>
        </div>
      )}
    </Container>
  );
};

export default ConfirmEmail;
