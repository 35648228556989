import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Paper, Alert, AlertTitle } from '@mui/material';
import { registerUser } from '../services/api';
import Logo from './Logo';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    linkAdress: window.location.origin,
  });

  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    confirmPassword: false,
  });
  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    confirmPassword: false,
  });

  const validateField = (name, value) => {
    let isValid = true;
    if (name === 'firstName' || name === 'lastName') {
      isValid = value.trim().length >= 2;
    }
    if (name === 'email') {
      isValid = /\S+@\S+\.\S+/.test(value);
    }
    if (name === 'password') {
      isValid = value.length >= 6;
    }
    if (name === 'confirmPassword') {
      isValid = value === formData.password;
    }
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Динамична проверка за всяко поле и за съвпадение на паролите
    setFormErrors({
      ...formErrors,
      [name]: !validateField(name, value),
      password: formData.password.length < 6,
      confirmPassword: formData.password !== formData.confirmPassword,
    });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });

    // Проверка за съвпадение на паролите и минимална дължина
    setFormErrors({
      ...formErrors,
      [name]: !validateField(name, formData[name]),
      password: formData.password.length < 6,
      confirmPassword: formData.password !== formData.confirmPassword,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Финална проверка дали има грешки
    if (Object.values(formErrors).some((error) => error)) {
      setError('Please correct the highlighted errors.');
      return;
    }

    try {
      const response = await registerUser(formData);
      alert(response.data.message);
      navigate('/login');
    } catch (err) {
      setError('Registration failed. Please try again.');
      console.error(err);
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '50px' }}>
      <Paper elevation={3} style={{ padding: '30px' }}>
        <Logo />
        <Typography variant="h4" align="center" gutterBottom>
          Create an Account
        </Typography>

        {error && (
          <Alert severity="error" style={{ marginTop: '20px' }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            margin="normal"
            variant="outlined"
            error={touched.firstName && formErrors.firstName}
            helperText={touched.firstName && formErrors.firstName ? 'First name must be at least 2 characters long' : ''}
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            margin="normal"
            variant="outlined"
            error={touched.lastName && formErrors.lastName}
            helperText={touched.lastName && formErrors.lastName ? 'Last name must be at least 2 characters long' : ''}
          />
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            margin="normal"
            variant="outlined"
            error={touched.email && formErrors.email}
            helperText={touched.email && formErrors.email ? 'Invalid email address' : ''}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            margin="normal"
            variant="outlined"
            error={touched.password && formErrors.password}
            helperText={touched.password && formErrors.password ? 'Password must be at least 6 characters long' : ''}
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            margin="normal"
            variant="outlined"
            error={touched.confirmPassword && formErrors.confirmPassword}
            helperText={touched.confirmPassword && formErrors.confirmPassword ? 'Passwords do not match' : ''}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            style={{ marginTop: '20px', padding: '10px' }}
          >
            Register
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => navigate('/login')}
            fullWidth
            style={{ marginTop: '20px', padding: '10px' }}
          >
            Login
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Register;
