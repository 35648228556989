import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Paper } from '@mui/material';
import { forgotPassword } from '../services/api';
import Logo from './Logo';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await forgotPassword({ email, url : window.location.origin });
      setMessage(response.data.message);
    } catch (err) {
      setMessage('Error sending reset password email. Please try again.');
      console.error(err);
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '50px' }}>
      <Paper elevation={3} style={{ padding: '30px' }}>
        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
          <Logo />
        </div>
        <Typography variant="h4" align="center" gutterBottom>
          Forgot Password
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            name="email"
            value={email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            style={{ marginTop: '20px', padding: '10px' }}
          >
            Send Reset Link
          </Button>
        </form>
        {message && (
          <Typography variant="body1" align="center" style={{ marginTop: '20px' }}>
            {message}
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
