import React, { useState, useEffect } from 'react';
import { Button, Container, Typography, Grid, Box, Snackbar, Alert } from '@mui/material';
import { setPin } from '../services/api';
import { Link, useNavigate } from 'react-router-dom';

const SetPin = () => {
  const [pinCode, setPinCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      showSnackbar('You need to log in to access this page', 'error');
      navigate('/login');
    } else {
      setLoading(true);
    }

    // Добавяме слушател за физическата клавиатура
    const handleKeyDown = (event) => {
      const key = event.key;

      if (!isNaN(key) && key >= '0' && key <= '9') {
        // Ако е натиснат цифров клавиш
        handleKeyPress(key);
      } else if (key === 'Backspace') {
        // Ако е натиснат "Backspace", трием последната цифра
        handleDelete();
      } else if (key === 'Enter') {
        // Ако е натиснат "Enter", изпращаме формата
        handleSubmit();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Премахваме слушателя за клавиатурата при напускане на компонента
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate, pinCode]);

  const handleKeyPress = (num) => {
    if (pinCode.length < 6) {
      setPinCode((prevPin) => prevPin + num);
    }
  };

  const handleDelete = () => {
    setPinCode((prevPin) => prevPin.slice(0, -1));
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      showSnackbar('Token is missing. Please log in again.', 'error');
      navigate('/login');
      return;
    }

    if (pinCode.length !== 6) {
      showSnackbar('Please enter exactly 4 digits for the PIN.', 'warning');
      return;
    }

    try {
      const response = await setPin({ pinCode }, token);
      showSnackbar(response.data.message, 'success');
      navigate('/dashboard');
    } catch (err) {
      showSnackbar(err.response.data.message, 'error');
      console.error(err);
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    loading && (
      <Container sx={{display: 'flex', alignItems:'center', justifyContent: 'center',flexDirection: 'column'}}>
         <Link to={'/dashboard'} style={{textAlign: 'center', fontSize: 30, margin: "10px 0"}}>
          Return to dashboard
        </Link>
        <Typography variant="h4" gutterBottom sx={{textAlign: 'center'}}>
          Set PIN Code
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={3}>
          <Typography variant="h5">
            {pinCode.padEnd(6, '•')} {/* Показва въведения ПИН, като скрива незапълнените места с "•" */}
          </Typography>

          {/* Клавиатурата */}
          <Grid container spacing={2} justifyContent="center" style={{ maxWidth: 300, marginTop: 20 }}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
              <Grid item xs={4} key={num}>
                <Button
                  variant="contained"
                  onClick={() => handleKeyPress(num.toString())}
                  style={{ width: '100%', height: '60px' }}
                >
                  {num}
                </Button>
              </Grid>
            ))}
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
                style={{ width: '100%', height: '60px' }}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                onClick={() => handleKeyPress('0')}
                style={{ width: '100%', height: '60px' }}
              >
                0
              </Button>
            </Grid>
          </Grid>

          {/* Бутон за потвърждение */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={pinCode.length !== 6}
            style={{ marginTop: '20px' }}
          >
            Set PIN
          </Button>
        </Box>

        {/* Snackbar за показване на съобщения */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    )
  );
};

export default SetPin;
