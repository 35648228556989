import axios from 'axios';

// const API_URL = 'http://195.8.222.62:22045';
// const API_URL = 'http://localhost:3000';
const API_URL = 'https://api.optasky.me';


export const registerUser = async (data) => {
  return await axios.post(`${API_URL}/register`, data);
};

export const loginUser = async (data) => {
  return await axios.post(`${API_URL}/login`, data);
};

export const forgotPassword = async (data) => {
  return await axios.post(`${API_URL}/forgot-password`, data);
};
export const resetPassword = async (data) => {
  return await axios.post(`${API_URL}/reset-password`, data);
};
export const setPin = async (data, token) => {
  return await axios.post(`${API_URL}/set-pin`, data, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

export const loginWithPin = async (data, token) => {
  return await axios.post(`${API_URL}/login-pin`, data,  {
    headers: {
      Authorization: `${token}`,
    },
  });
};
